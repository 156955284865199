import examsApi from '@api/exams'
import states from '@api/states'

const state = {
  data: null,
  meta: null,
  error: null
}

const mutations = {
  /**
   * Commits data
   *
   * @param {Object} state
   * @param {Object}
   */
  storeResponse(state, response) {
    state.data = response.data
    state.meta = response.meta
  },

  /**
   * @param {Object} state
   * @param {Object}
   */
  storeError(state, error) {
    state.error = error
  },

  /**
   * @param {Object} state
   * @param {Object}
   */
  clearResponse(state, response) {
    state.data = null
    state.meta = null
    state.error = null
  }
}

const getters = {
  /**
   * Returns the current state of the store
   *
   * @param {Object} state
   * @return {string}
   */
  state: state => {
    if (state.error) {
      return states.ERROR
    }

    if (!state.data) {
      return states.LOADING
    }

    return states.READY
  },

  /**
   * Returns the currently stored data
   *
   * @param {Object} state
   * @return {Array}
   */
  exams: state => {
    return state.data
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  examSuites: state => {
    if (!state.meta) {
      return []
    }
    return state.meta.examSuites
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  customExams: state => {
    if (!state.meta) {
      return []
    }
    return state.meta.customExams
  },

  /**
   * Formats Exam Suites for select options
   *
   * @return {Array}
   */
  examSuiteOptions: (state, getters) => {
    if (!getters.examSuites) {
      return []
    }

    return getters.examSuites.map(examSuite => {
      return {
        label: examSuite.name,
        value: examSuite.slug
      }
    })
  }
}

const actions = {
  init({ dispatch }) {
    //
  },

  /**
   * Gets exams and adds to store
   *
   * @param {Object} context
   * @param {string} examSuiteSlug
   * @return {Promise}
   */
  getClientExams({ commit }, examSuiteSlug) {
    commit('clearResponse')
    return examsApi.clientExamsLibrary(examSuiteSlug)
      .then(response => {
        commit('storeResponse', response)
      })
      .catch(error => {
        commit('storeError', error)
        throw error
      })
  }
}

export { state, mutations, getters, actions }
