import { pickedApiRequest } from './request'

export default {
  /**
   * Create a job
   *
   * @param {Object} job
   * @return {Object}
   */
  create (job) {
    return pickedApiRequest.post('assessment/jobs', job)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * All jobs
   *
   * @return {Array}
   */
  index () {
    return pickedApiRequest.get('assessment/jobs')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Update a job
   *
   * @param {string} id
   * @param {Object} job
   * @return {Object}
   */
  update (id, job) {
    return pickedApiRequest.post('assessment/jobs/' + id, job)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Close a job
   *
   * @param {string} id
   * @return {Object}
   */
  close (id) {
    return pickedApiRequest.post('assessment/jobs/' + id + '/close')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Open a job
   *
   * @param {string} id
   * @return {Object}
   */
  open (id) {
    return pickedApiRequest.post('assessment/jobs/' + id + '/open')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Show a job
   *
   * @param {string} id
   * @param {Object} job
   * @return {Object}
   */
  job (id) {
    return pickedApiRequest.get('assessment/jobs/' + id)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Move a candidate to a pipeline stage
   *
   * @param {string} organisation
   * @param {string} job
   * @param {string} candidate
   * @param {string} stage
   * @return {Object}
   */
  moveCandidateToStage (organisation, job, candidate, stage) {
    return pickedApiRequest.get('assessment/organisations/' + organisation + '/jobs/' + job + '/candidates/' + candidate + '/pipeline-candidate', {
      params: { slug: stage }
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Fetch candidates for a job
   *
   * @param {string} id
   * @param {Object} query
   * @param {Object} options
   * @return {Object}
   */
  candidates (id, query, options) {
    return pickedApiRequest.get('assessment/job-candidates/' + id, {
      params: { ...query, ...options }
    })
      .then(response => {
        return response.data
      })
  },

  /**
   * Gets a list of candidate invitations
   *
   * @param {String} jobUuid
   * @param {Object} query
   * @return {Array}
   */
  candidateInvitations (jobUuid, query) {
    return pickedApiRequest.get('assessment/jobs/' + jobUuid + '/candidate-invitations', {
      params: query
    })
      .then(response => {
        return response.data
      })
  },

  /**
   * @param {Object} file
   * @return {Object}
   */
  parseSpreadSheet (file) {
    return pickedApiRequest.post('assessment/spreadsheet-parser', file)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * @param {String} query
   * @return {Object}
   */
  searchJobRoles (query) {
    return pickedApiRequest.get('assessment/job-roles', {
      params: {
        q: query
      }
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * @param {String} jobSlug
   * @return {Object}
   */
  fetchJobRole (jobSlug) {
    return pickedApiRequest.get('assessment/job-role', {
      params: { slug: jobSlug }
    })
      .then(response => {
        return response.data.data
      })
  }
}
