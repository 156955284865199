import { pickedApiRequest, pickedApiCandidateRequest } from './request'

export default {
  /**
   * All exams
   *
   * @param {string} candidateToken
   * @return {Array}
   */
  index (candidateToken) {
    return pickedApiRequest.get('assessment/exams', {
      params: { candidateToken: candidateToken }
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Returns exam data (paginated)
   *
   * @return {Array}
   */
  clientExamsLibrary (examSuiteSlug) {
    const params = {
      examSuiteSlug
    }
    return pickedApiRequest.get('assessment/client-exams/library', {
      params
    })
      .then(response => {
        return response.data
      })
  },

  /**
   * Example exams
   *
   * @return {Array}
   */
  exampleExams () {
    return pickedApiRequest.get('assessment/example-exams')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Questions for an exam by slug
   *
   * @return {string} examSlug
   * @return {Array}
   */
  questions (examSlug) {
    return pickedApiRequest.get('assessment/exams/' + examSlug + '/questions')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Submit a candidate response
   *
   * @return {Object} attempt
   * @return {Object}
   */
  candidateAttempt (attempt) {
    return pickedApiRequest.post('assessment/candidate-attempts', attempt)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Submit a Candidate Wizard response
   *
   * @return {Object} attempt
   * @return {Object}
   */
  candidateWizardAttempt (attempt) {
    return pickedApiCandidateRequest.post('assessment/wizard/candidate-attempts', attempt)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Submit a candidate Personality exam result
   *
   * @return {Object} result
   * @return {Object}
   */
  candidatePersonalityAttempt (result) {
    return pickedApiRequest.post('assessment/candidate-personality-attempts', result)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Submit a Candidate Wizard Personality exam result
   *
   * @return {Object} result
   * @return {Object}
   */
  candidateWizardPersonalityAttempt (result) {
    return pickedApiCandidateRequest.post('assessment/wizard/candidate-personality-attempts', result)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Submit a candidate Drives exam result
   *
   * @return {Object} result
   * @return {Object}
   */
  candidateScoresAttempt (result) {
    return pickedApiRequest.post('assessment/candidate-scores-attempts', result)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Submit a Candidate Wizard Drives exam result
   *
   * @return {Object} result
   * @return {Object}
   */
  candidateWizardScoresAttempt (result) {
    return pickedApiCandidateRequest.post('assessment/wizard/candidate-scores-attempts', result)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Get a list of Exam Suites
   *
   * @return {Array}
   */
  examSuites () {
    return pickedApiRequest.get('assessment/exam-suites')
      .then(response => {
        return response.data.data
      })
  }
}
