import { getLocalStorage } from '@utils/localStorage'

export default [
  {
    path: '/',
    redirect: 'client-assessments'
  },
  {
    path: '/dashboard',
    redirect: 'client-assessments'
  },
  {
    path: '/personality-info',
    name: 'personality-info',
    component: () => lazyLoadView(import('@views/personality-info.vue'))
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => lazyLoadView(import('@views/sign-in.vue'))
  },
  {
    path: '/sign-in/magic-link',
    name: 'magic-link',
    component: () => lazyLoadView(import('@views/magic-link.vue'))
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => lazyLoadView(import('@views/sign-up.vue'))
  },
  {
    path: '/sign-up/validation-link',
    name: 'validate-email',
    component: () => lazyLoadView(import('@views/validate-email.vue'))
  },
  {
    path: '/sign-out',
    name: 'sign-out',
    component: () => lazyLoadView(import('@views/sign-out.vue'))
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => lazyLoadView(import('@views/reset.vue'))
  },
  {
    path: '/candidate-sign-in',
    name: 'candidate-sign-in',
    component: () => lazyLoadView(import('@views/candidate-sign-in.vue'))
  },
  /**
   * Client Assessments (formally Jobs)
   */
  {
    path: '/client-assessments',
    name: 'client-assessments-list',
    component: () => lazyLoadView(import('@views/client-assessments/list.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/client-assessments/create',
    name: 'client-assessments-create',
    component: () => lazyLoadView(import('@views/client-assessments/create.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/client-assessments/:id',
    name: 'client-assessments-show',
    component: () => lazyLoadView(import('@views/client-assessments/show.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/client-assessments/:id/analytics',
    name: 'client-assessments-analytics',
    component: () => lazyLoadView(import('@views/client-assessments/analytics.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/client-assessments/:id/edit',
    name: 'client-assessments-edit',
    component: () => lazyLoadView(import('@views/job-edit.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/client-assessments/:id/tests',
    name: 'client-assessments-exams',
    component: () => lazyLoadView(import('@views/client-assessments/exams.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/client-assessments/:id/candidates/:candidate',
    name: 'client-assessments-candidate',
    component: () => lazyLoadView(import('@views/client-assessments/candidate.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/client-assessments/:id/invite-by-email',
    name: 'client-assessments-invite',
    component: () => import('@views/client-assessments/invite-by-email.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/client-assessments/:job/invite',
    name: 'bulk-invite',
    component: () => import('@views/bulk-invite.vue')
  },
  /**
   * Tests Library
   */
  {
    path: '/client-tests',
    name: 'client-exams-list',
    component: () => lazyLoadView(import('@views/client-exams/list.vue')),
    meta: {
      authRequired: true
    }
  },
  // @TODO Remove
  // {
  //   path: '/invite',
  //   name: 'bulk-invite-no-job',
  //   component: () => import('@views/bulk-invite-no-job.vue')
  // },
  /**
   * Candidates
   */
  {
    path: '/candidates',
    name: 'candidates',
    component: () => lazyLoadView(import('@views/client-candidates/list.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/candidates/:id',
    name: 'candidate-profile',
    component: () => lazyLoadView(import('@views/client-candidates/show.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/candidates/:id/profile',
    name: 'candidate-profile-enhanced',
    component: () => lazyLoadView(import('@views/client-candidates/show-enhanced.vue')),
    meta: {
      authRequired: true
    }
  },
  /**
   * Results
   */
  {
    path: '/results',
    name: 'results',
    component: () => lazyLoadView(import('@views/results/list.vue')),
    meta: {
      authRequired: true
    }
  },
  /**
   * Settings
   */
  {
    path: '/settings',
    name: 'settings',
    redirect: { name: 'settings-profile' },
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/new-features',
    name: 'settings-legacy-help',
    component: () => lazyLoadView(import('@views/settings/legacy-help.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/profile',
    name: 'settings-profile',
    component: () => lazyLoadView(import('@views/settings/profile.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/plan',
    name: 'settings-plan',
    component: () => lazyLoadView(import('@views/settings/plan.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/payg-plan',
    name: 'settings-payg-plan',
    component: () => lazyLoadView(import('@views/settings/payg-plan.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/payg-change-plan',
    name: 'settings-payg-change-plan',
    component: () => lazyLoadView(import('@views/settings/payg-change-plan.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/checkout/:currency/:plan',
    name: 'settings-checkout',
    component: () => lazyLoadView(import('@views/settings/payg-checkout.vue')),
    meta: {
      authRequired: true,
      hideShell: true
    }
  },
  {
    path: '/settings/checkout-complete/:currency/:plan',
    name: 'settings-checkout-complete',
    component: () => lazyLoadView(import('@views/settings/payg-checkout-complete.vue')),
    meta: {
      authRequired: true,
      hideShell: true
    }
  },
  {
    path: '/settings/usage',
    name: 'settings-usage',
    component: () => lazyLoadView(import('@views/settings/usage.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/payg-usage',
    name: 'settings-payg-usage',
    component: () => lazyLoadView(import('@views/settings/payg-usage.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/upgrade-complete',
    name: 'settings-upgrade-complete',
    component: () => lazyLoadView(import('@views/settings/payg-upgrade-complete.vue')),
    meta: {
      authRequired: true,
      hideShell: true
    }
  },
  {
    path: '/settings/integrations',
    name: 'settings-integrations',
    component: () => lazyLoadView(import('@views/settings/integrations.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/api',
    name: 'settings-api',
    component: () => lazyLoadView(import('@views/settings/api.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/webhooks',
    name: 'settings-webhooks',
    component: () => lazyLoadView(import('@views/settings/webhooks.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/branding',
    name: 'settings-branding',
    component: () => lazyLoadView(import('@views/settings/branding.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/billing',
    name: 'settings-billing',
    component: () => lazyLoadView(import('@views/settings/billing.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/invoice/:invoice',
    name: 'settings-invoice',
    component: () => lazyLoadView(import('@views/settings/invoice.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/team',
    name: 'settings-team',
    component: () => lazyLoadView(import('@views/settings/team.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/pipeline',
    name: 'settings-pipeline',
    component: () => lazyLoadView(import('@views/settings/pipeline.vue')),
    meta: {
      authRequired: true
    }
  },
  /**
   * Subscriptions
   */
  {
    path: '/settings/subscription-options/:ref',
    name: 'subscription-options',
    component: () => lazyLoadView(import('@views/subscription-options.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/confirm-plan/:plan',
    name: 'confirm-plan',
    component: () => lazyLoadView(import('@views/confirm-plan.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/reactivate-plan',
    name: 'reactivate-plan',
    component: () => lazyLoadView(import('@views/reactivate-plan.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings/exports',
    name: 'settings-exports',
    component: () => lazyLoadView(import('@views/settings-exports.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/linkedin-callback',
    name: 'linkedin-callback',
    component: () => lazyLoadView(import('@assessments/candidate-wizard/views/linkedin-callback.vue')),
    meta: {
      candidateWizard: true
    }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => lazyLoadView(import('@views/feedback.vue')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/test-builder',
    name: 'test-builder',
    component: () => lazyLoadView(import('@views/test-builder.vue'))
  },
  {
    path: '/test-builder/:exam',
    name: 'test-builder-edit',
    component: () => lazyLoadView(import('@views/test-builder-edit.vue'))
  },
  {
    path: '/source',
    name: 'source',
    component: () => lazyLoadView(import('@views/source.vue')),
    meta: {
      authRequired: true
    }
  },
  /**
   * Assessments for candidates
   */
  {
    path: '/assessments',
    name: 'assessments',
    component: () => lazyLoadView(import('@assessments/views/assessments.vue')),
    meta: {
      assessment: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (!routeTo.query.candidate) {
          // If the user doesn't have a candidate ID query param
          // Redirect to the tests page
          next('/tests/')
        } else {
          // Continue to the assessments page
          next()
        }
      }
    }
  },
  {
    path: '/assessments/job/:job',
    name: 'assessments-open-sign-up',
    component: () => lazyLoadView(import('@assessments/views/open-sign-up.vue')),
    meta: {
      assessment: true
    }
  },
  {
    path: '/assessments/:assessment',
    name: 'assessment',
    component: () => lazyLoadView(import('@assessments/views/assessment.vue')),
    meta: {
      assessment: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (!routeTo.query.candidate) {
          // If the user doesn't have a candidate ID
          // Redirect to the tests page
          next('/tests/')
        } else {
          // Continue to the assessment test page
          next()
        }
      }
    }
  },
  {
    path: '/example-tests/:assessment',
    name: 'example-test',
    component: () => lazyLoadView(import('@views/example-test.vue')),
    meta: {
      assessment: true
    }
  },
  // {
  //   path: '/provisional-assessments/:group/:token',
  //   name: 'provisional-assessments',
  //   component: () => lazyLoadView(import('@assessments/views/provisional-assessment.vue'))
  // },
  // {
  //   path: '/provisional-assessments/:group/:token/:exam',
  //   name: 'provisional-assessments-exam',
  //   component: () => lazyLoadView(import('@assessments/views/provisional-assessment-exam.vue'))
  // },

  /**
   * Candidate Wizard
   */
  {
    path: '/ca/sign-in/magic-link',
    name: 'ca-magic-link',
    component: () => lazyLoadView(import('@views/candidate-magic-link.vue')),
    meta: {
      candidateWizard: true
    }
  },
  {
    path: '/ca/dashboard',
    name: 'candidate-wizard-dashboard',
    component: () => lazyLoadView(import('@assessments/candidate-wizard/views/candidate-wizard-dashboard.vue')),
    meta: {
      candidateWizard: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (!getLocalStorage('candidateWizard.uuid')) {
        // If there are no Candidate auth details
        // Redirect to the candidate-wizard page
        // @TODO - need the Job ID here
          next({ name: 'candidate-wizard' })
        } else {
        // Continue to the Wizard Dashboard
          next()
        }
      }
    }
  },
  {
    path: '/ca/profile/edit',
    name: 'candidate-wizard-profile-edit',
    component: () => lazyLoadView(import('@assessments/candidate-wizard/views/candidate-wizard-profile-edit.vue')),
    meta: {
      candidateWizard: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (!getLocalStorage('candidateWizard.uuid')) {
        // If there are no Candidate auth details
        // Redirect to the candidate-wizard page
        // @TODO - need the Job ID here
          next({ name: 'candidate-wizard' })
        } else {
        // Continue to the Wizard Dashboard
          next()
        }
      }
    }
  },
  {
    path: '/ca/sign-up/:job?',
    name: 'candidate-wizard',
    component: () => lazyLoadView(import('@assessments/candidate-wizard/views/candidate-wizard.vue')),
    meta: {
      candidateWizard: true
    }
  },
  {
    path: '/ca/sign-in/:job?',
    name: 'candidate-wizard-sign-in',
    component: () => lazyLoadView(import('@views/candidate-wizard-sign-in.vue')),
    meta: {
      candidateWizard: true
    }
  },
  {
    path: '/ca/jobs/:job/tests/:assessment',
    name: 'candidate-wizard-assessment',
    component: () => lazyLoadView(import('@assessments/candidate-wizard/views/candidate-wizard-assessment.vue')),
    meta: {
      assessment: true
    }
  },
  {
    path: '/ca/jobs/:job',
    name: 'candidate-wizard-job',
    component: () => lazyLoadView(import('@assessments/candidate-wizard/views/candidate-wizard-job.vue')),
    meta: {
      candidateWizard: true
    }
  },
  {
    path: '/ca/jobs/:job/qanda',
    name: 'candidate-wizard-personal-questions',
    component: () => lazyLoadView(import('@assessments/candidate-wizard/views/candidate-wizard-personal-questions.vue')),
    meta: {
      candidateWizard: true
    }
  },

  {
    path: '/404',
    name: '404',
    component: require('@views/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404'
  }
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    }
  })
}
