<template>
  <div v-if="hasOrganisationLoaded">
    <TrialBanner v-if="showTrialBanner" />
    <LegacyBanner v-if="!isPAYG" />
    <DiscountBanner
      v-else-if="notAPublicRoute"
      @bannerActive="discountBannerActivated = $event"
    />
  </div>
</template>

<script>
import TrialBanner from '@components/TrialBanner'
import LegacyBanner from '@components/LegacyBanner'
import DiscountBanner from '@components/DiscountBanner'

import { mapGetters } from 'vuex'

export default {
  components: {
    TrialBanner,
    LegacyBanner,
    DiscountBanner
  },

  data() {
    return {
      discountBannerActivated: false
    }
  },

  computed: {
    ...mapGetters({
      hasActiveSubscription: 'subscriptions/hasActiveSubscription',
      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      hasOrganisationLoaded: 'organisations/hasOrganisationLoaded',
      isPAYG: 'organisations/isPAYG'
    }),

    /**
     * @return {Boolean}
     */
    showTrialBanner() {
      return this.$usesFeature('trials') &&
      this.haveSubscriptionsLoaded &&
      !this.hasActiveSubscription &&
      this.notAPublicRoute &&
      !this.discountBannerActivated
    },

    notAPublicRoute() {
      const publicRoutes = [
        'sign-in',
        'sign-up',
        'sign-out',
        'magic-link',
        'candidate-sign-in',
        'feedback'
      ]
      return !publicRoutes.includes(this.$route.name)
    }
  },

  watch: {
    discountBannerActivated(banner) {
      if (banner) {
        this.$emit('bannersActivated', true)
      }
    },

    showTrialBanner(banner) {
      if (banner) {
        this.$emit('bannersActivated', true)
      }
    },

    isPAYG(isPAYG) {
      if (!isPAYG) {
        this.$emit('bannersActivated', true)
      }
    }
  }
}
</script>
