<template>
  <div
    class="flex items-center justify-center px-6 py-2 text-xs text-white bg-secondary sm:text-sm"
  >
    <p
      class="font-medium sm:text-center"
      v-html="trialText"
    ></p>

    <router-link
      :to="{ name: 'settings-plan' }"
      class="w-auto px-2 py-1 mx-2 text-center text-gray-800 transition-all duration-200 ease-out bg-white rounded hover:bg-gray-100 focus:outline-none"
    >
      Upgrade
    </router-link>
    <p>{{ upgradeText }}</p>
  </div>
</template>

<script>
import { differenceInDays } from 'date-fns'
import { mapGetters } from 'vuex'
import pluralize from 'pluralize'

/**
 * This compontent assumes that hasActiveSubscription has already been used
 * to hide the banner, so this should not load if there is a subscription
 */

export default {
  data() {
    return {
      differenceInDays,
      satelliteName: process.env.VUE_APP_SATELLITE_NAME
    }
  },

  computed: {
    ...mapGetters({
      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      hasOrganisationLoaded: 'organisations/hasOrganisationLoaded',
      inTrial: 'organisations/inTrial',
      trialEndedAt: 'organisations/trialEndedAt',
      trialStartedAt: 'organisations/trialStartedAt'
    }),

    /**
     * @return {Number}
     */
    trialDaysRemaining() {
      return this.differenceInDays(new Date(this.trialEndedAt), new Date())
    },

    /**
     * @return {String}
     */
    trialText() {
      if (!this.inTrial) {
        return `Your trial has expired`
      }

      if (this.trialDaysRemaining === 0) {
        return `Your trial ends today`
      }

      return `You have <span class="inline-block px-3 py-1 font-bold rounded text-primary-offset bg-primary">${this.trialDaysRemaining}</span> ${pluralize('days', this.trialDaysRemaining)} left in your ${this.satelliteName} trial`
    },

    /**
     * @return {String}
     */
    upgradeText() {
      if (!this.inTrial && this.trialDaysRemaining === 0) {
        return 'to unlock your results'
      }
    }
  }
}
</script>
