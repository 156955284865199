<template>
  <div class="z-40 w-full h-16 bg-primary">
    <header
      class="z-40 w-full h-16 max-w-screen-xl mx-auto text-left sm:text-center"
    >
      <div
        class="sm:flex sm:items-center sm:justify-between sm:h-16 bg-primary"
      >
        <div class="flex items-center justify-between px-6 py-4">
          <div v-if="!childOrganisations">
            <router-link
              v-if="isAuthorised"
              class="flex items-center text-white sm:justify-between"
              :to="{ name: 'client-assessments-list' }"
            >
              <component :is="siteLogo" />
            </router-link>
            <a
              v-else
              class="flex items-center text-white sm:justify-between"
              href="/"
            >
              <component :is="siteLogo" />
            </a>
          </div>
          <div
            v-if="childOrganisations && childOrganisation"
            class="my-1"
          >
            <Dropdown class="hidden sm:block">
              <template
                #trigger="{ hasChildOrganisationsFocus, isChildOrganisationsOpen }"
              >
                <span
                  class="flex items-center justify-center block h-10 px-2 overflow-hidden tracking-wide border-2 rounded text-primary"
                  :class="[
                    hasChildOrganisationsFocus || isChildOrganisationsOpen
                      ? 'border-white xl:border-secondary'
                      : 'border-gray-600 xl:border-gray-300',
                  ]"
                >
                  <span>{{ childOrganisation.name }}</span>
                  <svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-6 h-6 ml-2 chevron-down"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </template>
              <template #dropdown-left>
                <ul
                  class="w-64 py-6 mt-6 text-left bg-white shadow-2xl xl:border"
                >
                  <li
                    v-for="child in childOrganisations"
                    :key="child.id"
                  >
                    <button
                      class="inline-block px-6 py-2 text-gray-600"
                      @click="changeChildOrganisation(child)"
                    >
                      {{ child.name }}
                    </button>
                  </li>
                </ul>
              </template>
            </Dropdown>
          </div>

          <div class="flex sm:hidden">
            <button
              type="button"
              class="px-2 text-black text-gray-500 duration-150 hover:text-primary focus:outline-none"
              @click="toggle"
            >
              <svg
                class="w-8 h-8 fill-current"
                viewBox="0 0 24 24"
              >
                <path
                  v-if="isOpen"
                  class="duration-200"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
                <path
                  v-if="!isOpen"
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              </svg>
            </button>
          </div>
        </div>

        <nav
          class="px-2 duration-200 border-b sm:flex sm:items-center sm:px-4 xl:justify-between border-secondary sm:px-0 sm:border-0"
          :class="{ hidden: !isOpen, block: isOpen }"
        >
          <ul
            v-if="!isAuthorised"
            class="px-6 -mx-4 sm:flex sm:items-center sm:px-0 text-primary"
          >
            <li class="p-4 sm:py-0 lg:px-6">
              <a
                href="/product/"
                class="inline-block duration-150 underline-from-center"
              >
                Product
              </a>
            </li>
            <li
              v-if="usesAptitudePage"
              class="p-4 sm:py-0 lg:px-6"
            >
              <a
                href="/tests/"
                class="inline-block duration-150 underline-from-center"
              >
                Tests
              </a>
            </li>
            <li
              v-if="usesAssessments"
              class="p-4 sm:py-0 lg:px-6"
            >
              <a
                href="/our-assessments/"
                class="inline-block duration-150 underline-from-center"
              >
                Assessments
              </a>
            </li>
            <li
              v-if="usesPlansMenuItem"
              class="p-4 sm:py-0 lg:px-6"
            >
              <a
                href="/plans/"
                class="inline-block duration-150 underline-from-center"
              >
                Plans
              </a>
            </li>
            <li
              v-if="usesScience"
              class="p-4 sm:py-0 lg:px-6"
            >
              <a
                href="/science/"
                class="inline-block duration-150 underline-from-center"
              >
                Science
              </a>
            </li>
            <li class="p-4 sm:py-0 lg:px-6">
              <a
                href="/clients/"
                class="inline-block duration-150 underline-from-center"
              >
                Case Studies
              </a>
            </li>
            <li class="p-4 sm:py-0 lg:px-6">
              <a
                href="/magazine/"
                class="inline-block duration-150 underline-from-center"
              >
                Blog
              </a>
            </li>
            <li class="p-4 sm:py-0 lg:px-6">
              <router-link
                :to="{ name: 'sign-in' }"
                class="inline-block duration-150 underline-from-center"
              >
                Sign In
              </router-link>
            </li>
          </ul>

          <ul
            v-if="isAuthorised"
            class="px-6 -mx-4 sm:flex sm:items-center sm:px-0 text-primary signed-out:hidden"
          >
            <HeaderLink
              v-if="specialFeatures.includes('talentSource')"
              :to="{ name: 'source' }"
            >
              Source
            </HeaderLink>
            <HeaderLink :to="{ name: 'client-assessments-list' }">
              Assessments
            </HeaderLink>
            <HeaderLink :to="{ name: 'candidates' }">
              Candidates
            </HeaderLink>
            <HeaderLink :to="{ name: 'client-exams-list' }">
              Tests
            </HeaderLink>
            <li
              v-if="
                haveSubscriptionsLoaded &&
                  !hasActiveSubscription &&
                  !$usesFeature('trials')
              "
              class="w-32 p-2 pb-4 sm:pb-2 lg:px-6 sm:w-auto"
            >
              <router-link
                :to="{ name: 'settings-plan' }"
                class="flex items-center h-10 px-4 font-bold tracking-wide uppercase duration-150 border-2 rounded text-secondary border-secondary hover:text-secondary-400 hover:border-secondary-400"
              >
                Upgrade
              </router-link>
            </li>
            <li
              v-if="!paygSubscription && isPAYG && hasSubscriptionLoaded"
              class="w-32 p-2 pb-4 sm:pb-2 lg:px-6 sm:w-auto"
            >
              <router-link
                :to="{ name: 'settings-payg-plan' }"
                class="flex items-center h-10 px-4 font-bold tracking-wide uppercase duration-150 border-2 rounded text-secondary border-secondary hover:text-secondary-400 hover:border-secondary-400"
              >
                Upgrade
              </router-link>
            </li>
            <li
              class="p-2 border-t border-gray-800 lg:px-4 sm:hidden signed-out:hidden"
            >
              <ul>
                <li class="py-4">
                  <div class="flex items-center sm:hidden">
                    <span class="text-xl font-semibold sm:hidden">{{
                      name
                    }}</span>
                  </div>
                </li>
                <li class="py-4">
                  <router-link
                    :to="{ name: 'results' }"
                    class="inline-block duration-150 underline-from-center"
                  >
                    Results
                  </router-link>
                </li>
                <li class="py-4">
                  <router-link
                    :to="{ name: 'settings-plan' }"
                    class="inline-block duration-150 underline-from-center"
                  >
                    Plan
                  </router-link>
                </li>
                <li class="py-4">
                  <router-link
                    :to="{ name: 'settings-profile' }"
                    class="inline-block duration-150 underline-from-center"
                  >
                    Settings
                  </router-link>
                </li>
                <li class="py-4">
                  <a
                    href="https://support.testcandidates.com/"
                    target="_blank"
                    class="inline-block duration-150 underline-from-center"
                  >
                    Support
                  </a>
                </li>
                <li
                  v-if="usesFaqForSupport"
                  class="py-4"
                >
                  <a
                    href="https://support.picked.ai/"
                    target="_blank"
                    class="inline-block duration-150 underline-from-center"
                  >
                    Support
                  </a>
                </li>
                <li class="py-4">
                  <router-link
                    :to="{ name: 'sign-out' }"
                    class="inline-block duration-150 underline-from-center"
                  >
                    Sign Out
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>

          <ul
            v-if="isAuthorised"
            class="hidden sm:flex sm:items-center text-primary signed-out:hidden"
          >
            <li class="p-2 sm:px-4 sm:ml-2">
              <Dropdown class="hidden sm:block">
                <template #trigger="{ hasFocus, isOpen }">
                  <span
                    class="flex items-center justify-center block w-10 h-10 overflow-hidden tracking-wide border-2 rounded"
                    :class="[
                      hasFocus || isOpen
                        ? 'border-white xl:border-secondary'
                        : 'border-gray-600 xl:border-gray-300',
                    ]"
                  >
                    <span>{{ nameToInitials(name) }}</span>
                  </span>
                </template>
                <template #dropdown>
                  <ul class="w-64 py-6 mt-6 text-left bg-white shadow-2xl xl:border">
                    <li>
                      <div class="px-6 pb-2">
                        <div class="flex items-center">
                          <div class="flex flex-col overflow-hidden leading-tight">
                            <span class="font-semibold text-gray-800 truncate">{{
                              name
                            }}</span>
                            <span class="text-xs text-gray-600 truncate">{{
                              email
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="border-t border-gray-300 mt-3 pt-3">
                      <router-link
                        :to="{ name: 'results' }"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Results
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'test-builder' }"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Custom Tests
                      </router-link>
                    </li>
                    <li class="border-t border-gray-300 mt-3 pt-3">
                      <router-link
                        :to="{ name: 'settings-profile' }"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Profile
                      </router-link>
                    </li>
                    <li v-if="isPAYG">
                      <router-link
                        :to="{ name: 'settings-payg-plan' }"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Plan
                      </router-link>
                    </li>
                    <li v-if="isPAYG && paygSubscription">
                      <router-link
                        :to="{ name: 'settings-payg-usage' }"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Usage
                      </router-link>
                    </li>
                    <li v-if="!isPAYG">
                      <router-link
                        :to="{ name: 'settings-plan' }"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Plan
                      </router-link>
                    </li>
                    <li v-if="!isPAYG">
                      <router-link
                        :to="{ name: 'settings-usage' }"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Usage
                      </router-link>
                    </li>
                    <li class="border-t border-gray-300 mt-3 pt-3">
                      <a
                        :href="supportUrl"
                        target="_blank"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Support
                      </a>
                    </li>
                    <li>
                      <a
                        href="/contact/"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Contact
                      </a>
                    </li>
                    <li>
                      <a
                        href="/feedback/"
                        class="inline-block px-6 py-2 text-gray-600"
                      >
                        Feedback
                      </a>
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'sign-out' }"
                        class="block px-6 pt-2 mt-0 text-gray-600"
                      >
                        Sign out
                      </router-link>
                    </li>
                  </ul>
                </template>
              </Dropdown>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import Dropdown from '@components/Dropdown'
import HeaderLink from '@components/HeaderLink'

import { nameToInitials } from '@utils/nameToInitials'
import { mapGetters } from 'vuex'

export default {
  components: {
    Dropdown,
    HeaderLink
  },

  data() {
    return {
      nameToInitials,
      satellite: process.env.VUE_APP_SATELLITE,
      supportUrl: process.env.VUE_APP_SUPPORT_URL,
      isOpen: false,
      isChildOrganisationsOpen: null
    }
  },

  computed: {
    ...mapGetters({
      isAuthorised: 'employers/isAuthorised',
      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      hasActiveSubscription: 'subscriptions/hasActiveSubscription',
      paygSubscription: 'subscriptions/paygSubscription',
      name: 'employers/name',
      email: 'employers/email',
      childOrganisations: 'employers/childOrganisations',
      childOrganisation: 'employers/childOrganisation',
      specialFeatures: 'organisations/specialFeatures',
      isPAYG: 'organisations/isPAYG',
      hasSubscriptionLoaded: 'subscriptions/hasSubscriptionLoaded'
    }),

    siteLogo() {
      return () => import('@components/SiteLogos/' + this.satellite)
    },

    /**
     * @return {string}
     */
    childOrganisationId() {
      return localStorage.getItem('employer.childOrganisationId')
    },

    /**
     * @return {Boolean}
     */
    usesPlansMenuItem() {
      return this.$usesFeature('plans-menu-item')
    },

    /**
     * @return {Boolean}
     */
    usesAssessments() {
      return this.$usesFeature('assessments')
    },

    /**
     * @return {Boolean}
     */
    usesAptitudePage() {
      return this.$usesFeature('aptitude')
    },

    /**
     * @return {Boolean}
     */
    usesScience() {
      return this.$usesFeature('science')
    },

    /**
     * @return {Boolean}
     */
    usesExternalSupport() {
      return this.$usesFeature('external-support')
    },

    /**
     * @return {Boolean}
     */
    usesFaqForSupport() {
      return this.$usesFeature('faq-support')
    }
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },

    /**
     * Organisation has been changed by the user
     *
     * @param {Object} childOrganisation
     */
    changeChildOrganisation(childOrganisation) {
      this.$store
        .dispatch('employers/setChildOrganisationId', childOrganisation.id)
        .then(() => {
          location.reload()
        })
    },

    beforeEnter(el) {
      el.style.height = '0'
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave(el) {
      el.style.height = '0'
    }
  }
}
</script>
