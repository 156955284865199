<template>
  <Table>
    <slot></slot>
  </Table>
</template>

<script>
import Table from '@components/TUI/Tables/Table'

export default {
  components: {
    Table
  }
}
</script>
