<template>
  <nav
    class="flex mb-1"
    aria-label="Breadcrumb"
  >
    <ol class="flex items-center space-x-2">
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
      >
        <div class="flex items-center space-x-2">
          <!-- Heroicon name: chevron-right -->
          <svg
            v-if="index !== 0"
            class="flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <router-link
            v-if="breadcrumb.to && $route.name !== breadcrumb.to"
            :to="{ name: breadcrumb.to, params: breadcrumb.params }"
            class="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out"
          >
            {{ breadcrumb.name }}
          </router-link>
          <span
            v-else
            class="text-sm leading-5 font-medium text-gray-500"
          >
            {{ breadcrumb.name }}
          </span>
        </div>
      </li>
      <!--
        If an empty array is passed, or is missing, then just show a dash
        for a loading state so the page doesn’t jiggle about
      -->
      <li v-if="breadcrumbs.length === 0">
        <div class="flex items-center space-x-4">
          <span class="text-sm leading-5 font-medium text-gray-500">
            –
          </span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    /**
     * Return an array of the breadcrumbs with the objects:
     * {
     *   name: 'Name',
     *   to: 'to-name'
     * }
     *
     * Returns an empty array by default. Do not show breadcrumbs, do not render
     * the compontent.
     *
     * @return {Array}
     */
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  }
}
</script>
